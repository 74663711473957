import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Volunteer from './pages/Volunteer';
import Contact from './pages/Contact';
import ErrorPage from './sections/error-page';
import Faqs from './pages/Faqs';



const router = createBrowserRouter([
  {
    path: "/",
  
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "",
        element: <Home text={"dashboard"} />,
      },
      {
        path: "home",
        element: <Home text={"dashboard"} />,
      },
      
      {
        path: "faqs",
        element: <Faqs />,
      },
     
      
    ]

  },
  
  
])

ReactDOM.render(
  <React.StrictMode>
   <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
