import React from "react";
import faqData from "../data/faqData.json";
import FooterSection from "../sections/footer-section";

const Faqs = () => {
  // Split the FAQs into two columns
  const midpoint = Math.ceil(faqData.faqs.length / 2);
  const leftColumnFaqs = faqData.faqs.slice(0, midpoint);
  const rightColumnFaqs = faqData.faqs.slice(midpoint);

  const renderFaq = (faq) => (
    <div class="mb-10" key={faq.question}>
      <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900">
        <svg
          class="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          ></path>
        </svg>
        {faq.question}
      </h3>
      <p class="text-gray-500">{faq.answer}</p>
    </div>
  );

  return (
    <div id="faqs" className="pt-20">
      <section class="bg-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900">
            {faqData.title}
          </h2>
          <p class="mb-8 text-gray-500">{faqData.description}</p>
          <div class="grid pt-8 text-left border-t border-gray-200 md:gap-16 md:grid-cols-2">
            <div>
              {leftColumnFaqs.map(renderFaq)}
            </div>
            <div>
              {rightColumnFaqs.map(renderFaq)}
            </div>
          </div>
        </div>
      </section>
      <FooterSection/>
    </div>
  );
};

export default Faqs;