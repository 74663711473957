import React from 'react'
import logo from '../assets/MaluhiaHospiceLogo.png'

const Navbar = () => {
  
  
  return (
    <header className="bg-white shadow w-full fixed py-2 z-50">
    <div className="mx-auto max-w-screen-xl ">
      <div className="flex h-16 items-center justify-between">
        <div className="flex-1 md:flex md:items-center md:gap-12">
        <a
      href="https://maluhiahospice.com"
      class="flex items-center space-x-3 rtl:space-x-reverse"
    >
      <img src={logo} class="h-16" alt="OuiTrend Logo" />
      
    </a>
        </div>

        <div className="md:flex md:items-center md:gap-12">
          <nav aria-label="Global" className="hidden md:block">
            <ul className="flex items-center gap-6 text-sm">
              <li>
                <a
                  className="text-gray-700 transition hover:text-gray-500/75"
                  href="home"
                >
                  {" "}
                  Home{" "}
                </a>
              </li>

              <li>
                <a
                  className="text-gray-500 transition hover:text-gray-500/75"
                  href="about"
                >
                  {" "}
                  About{" "}
                </a>
              </li>

              <li>
                <a
                  className="text-gray-500 transition hover:text-gray-500/75"
                  href="volunteer"
                >
                  {" "}
                  Volunteer{" "}
                </a>
              </li>

              <li>
                <a
                  className="text-gray-500 transition hover:text-gray-500/75"
                  href="contact"
                >
                  {" "}
                  Contact{" "}
                </a>
              </li>
              <li>
                <a
                  className="text-gray-500 transition hover:text-gray-500/75"
                  href="faqs"
                >
                  {" "}
                  FAQs{" "}
                </a>
              </li>

             

              
            </ul>
          </nav>

         
        </div>
      </div>
    </div>
    
  </header>
  )
}

export default Navbar
