import logo from "./assets/MaluhiaHospiceLogo.png";
import background from "./assets/care.jpg";
import "./App.css";
import React from "react";
import Navbar from "./sections/navbar";
import HeaderSection from "./sections/header-section";
import AboutUsSection from "./sections/aboutus-section";
import ServicesSection from "./sections/services-section";
import ContactUsSection from "./sections/contactus-section";
import FooterSection from "./sections/footer-section";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Outlet/>
    </div>
  );
}

export default App;
