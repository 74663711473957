import React from "react";
import HeaderSection from "../sections/header-section";
import AboutUsSection from "../sections/aboutus-section";
import ServicesSection from "../sections/services-section";
import FooterSection from "../sections/footer-section";
import Navbar from "../sections/navbar";

const Home = () => {
  return (
    <div id="home">
      <Navbar/>
      <HeaderSection />
      <AboutUsSection />
      <ServicesSection/>
      <FooterSection />
    </div>
  );
};

export default Home;
