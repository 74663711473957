import React from 'react'
import image from '../assets/care.jpg'

const AboutUsSection = () => {
  return (
    <section>
        <div className="mx-auto max-w-screen-2xl py-16 ">
          <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
            <div className="relative z-10 lg:py-16">
              <div className="relative h-64 sm:h-80 lg:h-full">
                <img
                  alt=""
                  src={image}
                  className="absolute inset-0 h-full w-full object-cover"
                />
              </div>
            </div>

            <div className="relative flex items-center bg-gray-100 border border-maluhia-blue">
              <span className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-gray-100"></span>

              <div className="p-8 sm:p-16 lg:p-24">
                <h2 className="text-2xl font-bold sm:text-3xl">
                  Our Mission
                </h2>

                <p className="mt-4 text-gray-600">
                Maluhia Hospice Care, PLLC is dedicated to our mission of providing the highest quality of care, respect, compassion, and support to all patients and families during their end-of-life journey, ensuring they experience peace, tranquility, and serenity.
                </p>

                <a
                  href="#"
                  className="mt-8 inline-block rounded border border-maluhia-blue bg-maluhia-blue px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-maluhia-blue focus:outline-none focus:ring active:text-indigo-500"
                >
                  Get in Touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default AboutUsSection