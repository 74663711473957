import React from "react";
import image from "../assets/eolc.jpg"

const ServicesSection = () => {

        



  return (
    <section id="services" className="bg-white ">
      <div className="container px-6 py-10 mx-auto">
        

        <div className="mt-2">
          <span className="inline-block w-64 h-1 bg-maluhia-blue rounded-full"></span>
          <span className="inline-block w-64 h-1 ml-1 bg-maluhia-blue rounded-full"></span>
          <span className="inline-block w-64   h-1 ml-1 bg-maluhia-blue rounded-full"></span>
        </div>

        <div className="mt-8 xl:mt-12 lg:flex lg:items-center">
          <div className="grid w-full grid-cols-1 gap-8 lg:w-1/2 xl:gap-16 md:grid-cols-2">
            
            
            <div className="space-y-3  p-5 bg-gray-100 ">
              <h1 className="text-xl font-semibold text-gray-700 capitalize ">
                Empowered End-of-Life Care
              </h1>

              <p className="text-gray-500 ">
                Empower and engage patients and their caregivers in
                understanding and utilizing end-of-life care resources and
                support.
              </p>
            </div>

            <div className="space-y-3 p-5 bg-gray-100">
              <h1 className="text-xl font-semibold text-gray-700 capitalize">
                Comprehensive Family Support
              </h1>

              <p className="text-gray-500 ">
                Delivering comprehensive care that addresses the physical,
                social, emotional, and spiritual needs of both the patient and
                their family.
              </p>
            </div>

            <div className="space-y-3 p-5 bg-gray-100">
              <h1 className="text-xl font-semibold text-gray-700 capitalize ">
                Team Collaboration
              </h1>

              <p className="text-gray-500">
                Optimal utilization of a hospice team, which includes
                physicians, nurses, social workers, and therapists collaborating
                seamlessly to ensure the delivery of top-notch care.
              </p>
            </div>

            <div className="space-y-3 p-5 bg-gray-100">
              <h1 className="text-xl font-semibold text-gray-700 capitalize">
                Grief Support
              </h1>

              <p className="text-gray-500">
                Continuing bereavement support for the family in the aftermath
                of their loss.
              </p>
            </div>
          </div>

          <div className="hidden lg:flex lg:w-1/2 lg:justify-center">
            <img
              className="w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem]"
              src={image}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
