import React from "react";
import hawaiiImage from '../assets/hawaii.jpg'; // Make sure the path is correct

const HeaderSection = () => {
  const words = ["PEACE", "QUIET", "SECURITY", "TRANQUILITY"];
  const [currentWordIndex, setCurrentWordIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change word every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section
      className="relative flex items-center justify-start min-h-screen"
      style={{
        backgroundImage: `url(${hawaiiImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
      <div className="max-w-screen-xl px-4 py-32 w-full relative z-10 text-white">
        <div className="max-w-3xl mx-auto">
          
          
          <p className="text-5xl font-extrabold text-maluhia-blue">
            {words[currentWordIndex]}
          </p>

          <p className="mt-4 sm:text-xl">
            Maluhia Hospice is dedicated to our community, patients, and families with unwavering excellence in hospice care. We understand the unique emotions and uncertainties that come with end-of-life challenges. Our commitment is to bring hope, enrichment, and personalized support to every patient and family we serve. Thank you for being a part of our journey.
          </p>

          <div className="mt-8 flex flex-wrap justify-start gap-4">
            <a
              className="block w-full sm:w-auto rounded border border-maluhia-blue bg-maluhia-blue px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-maluhia-blue focus:outline-none focus:ring active:text-opacity-75"
              href="#"
            >
              Connect with us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderSection;